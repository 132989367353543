<template>
  <div class="page" style="height: 100%">
    <van-nav-bar title="消息推送" left-arrow @click-left="$router.back()"></van-nav-bar>
    <van-form style="margin-top: 16px">
      <van-field label="topic" type="text" v-model="topic" placeholder="请输入topic" />
      <van-field
          v-model="content"
          rows="2"
          autosize
          label="内容"
          type="textarea"
          placeholder="请输入内容"
      />
    </van-form>
    <van-button round block style="width: 80%; margin: 20px auto" type="primary" @click="pushMsg">推送</van-button>
  </div>
</template>

<script>
export default {
  name: "pushMessage",
  data() {
    return {
      topic: '',
      content: ''
    }
  },
  methods: {
    pushMsg() {
      if (this.topic === '' || this.content === '') {
        this.$toast.fail('请输入topic和内容')
        return
      }
      this.$http.post('cabinet/mqtt/message', {topic: this.topic, content: this.content}).then(res => {
        if (res.code === 200) {
          this.$toast.success('发布成功')
        } else {
          this.$toast.fail(res.msg)
        }
      }).catch(err => {
        this.$toast.fail(err.toLocaleString())
      })
    }
  }
}
</script>

<style scoped>

</style>
